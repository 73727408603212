* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Nunito", sans-serif;
}

button {
  border: none;
  outline: none;
  /* width: 100%; */

  color: #fff;
  font-size: 16px;
  letter-spacing: 1px;
  background: #edd900;
  cursor: pointer;
}

.login {
  width: 100%;
  min-height: 100vh;
  padding: 0 20px;
  background-color: #233237;
  display: flex;
}

.login .loginContainer {
  padding: 60px;
  margin: auto;
  width: 100%;
  max-width: 520px;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #1d2731;
  box-shadow: 0 50px 70px -20px rgba(0, 0, 0, 0.8);
  align-items: center;
}

.login .loginContainer label {
  color: white;
  margin: 14px 0;
  display: block;
  font-size: 22px;
  line-height: 1;
  text-align: left;
  width: 100%;
}

.login .loginContainer input {
  width: 100%;
  border: none;
  outline: none;
  font-size: 19px;
  padding: 10px;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  letter-spacing: 1px;
}

.login .loginContainer .btnContainer {
  width: 100%;
  padding: 24px 0;
}

.login .loginContainer .btnContainer button {
  padding: 10px 15px;
  width: 100%;
}

.login .loginContainer .btnContainer p {
  margin: 14px 0 0 0;
  text-align: right;
  color: #fff;
  font-size: 10px;
}

.login .loginContainer .btnContainer p span {
  color: yellow;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin-left: 5px;
  cursor: pointer;
  transition: all 400ms ease-in-out;
}

.login .loginContainer .btnContainer p span:hover {
  color: red;
}

.login .loginContainer .errorMsg {
  color: red;
  font-size: 16px;
}

.login .logo {
  width: 150px;
  height: 150px;
  background-size: cover;
  margin-right: -50px;
}

body {
    margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
  /* background: blue; */
  /* text-align: center; */
}

.homeContainer {
  background-color: #233237;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* text-align: center; */
}

.navbar {
  /* flex:1; */
  height: 70px;
  /* background-color: purple; */
}

.homeContentContainer {
  flex:1;
  display: flex;
      flex-direction: column;

  /* background-color: bisque; */
}

.questionHolder {
  /* background-color: tomato; */
  flex: 1 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}

.questionHolder .introContainer {
  padding-top: 50px;
  text-align: center;
  color: #fff;
}

.questionHolder .resultHoder {
  text-align: center;
  color:white;
}

.categoryChosser {
  color: white;
  /* text-align: center */
}

.categoryChosser input {
  width: 50px;
  border: 1px solid #edd900;
  border-radius: 30px;
  background-color: inherit;
  padding-left: 10px;
  padding-top: 3px;
  color: white;

}

.centerRow {
  display: flex;
  justify-content: center;
}

.centerRow select {
  border: 1px solid #edd900;
  border-radius: 30px;
  background-color: inherit;
  color: white;
}

.centerRow select option {
  background-color: #233237;
  color: white;
}

.transcriptContent {
  /* background-color: white; */
  padding-top: 20px;
  padding-left: 5px;
  padding-right: 5px;
  overflow-y: auto;
  text-align: left;
  font-size: 12px;
}

.transcriptContent h3 {color: white; text-align: center;}
.transcriptContent .transcript-question-text {
  padding-top: 20px;
  color: white;
  font-size: 14px;
}

.transcriptContent .transcript-question-result {
  color: grey;
}

.transcriptContent .transcript-answer-wrapper {
  padding-left: 10px;
}

.modal-body .transcriptContent .transcript-question-text {
  color: black;
}

.modal-body .transcriptContent .transcript-question-result {
  color: red
}

.question {
  flex: 6;
  /* background-color: turquoise; */
  text-align: left;
  color:white;
  padding: 0 5px;
}

.question > div {
  padding: 5px;
}

.questionIndex {
  color: red;
  padding-right: 10px;
}

.questionPic {
  /* background-color: teal; */
  flex: 1;
}

.questionPic .picThumbnail {
  object-fit: scale-down;
  height: 200px;
  width: 100%;
}

.questionAnswers {
  /* background-color: springgreen; */
  display: flex;
  /* flex: 1; */
  padding: 0 10px;

}



.submitQuestionsHolder {
  /* background-color: sienna; */
  height: 40px;
  text-align: center;
}




.answer {
  display: flex;
  width: 75%;
  height: 2em;
  margin: 4px 5px;
  border-radius: 6px;
  line-height: 2em;
}

.answer-order-left {
  display: inherit;
  width: 2em;
  height: inherit;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 6px 0 0 6px;
  align-items: center;
  justify-content: center;
  line-height: 2em;
}

.answer-order-right {
  display: inherit;
  width: 2em;
  border-radius: 0 6px 6px 0;
}

.answer-text {
  display: inherit;
  width: 100%;
  align-items: center;
  justify-content: center;
}


/* .transcriptsHolder {
  padding-top: 20px;
} */

.transcriptsHolder ul {
  list-style-type: none;
}


.formContainer {
 display: flex;
 flex-direction: row;
}

.questionFormContainer {
  flex: 1;
}

.viewQuestionsContainer {
  flex:1;
  overflow-y: scroll;
  height: 90vh;
}

.viewQuestionsContainer button {
  width: 30px;
}

.answersContainer {
  display:flex; 
  flex-direction: column;
  height: 20vh;
  width: 100%;
  /* background: #333; */
  overflow-y: scroll;
  /* margin-bottom: 20px; */
}


.answersContainer .form-group button {
  width: 31px;
  border-radius: 28px;
}

.form-row .answerRow {
  margin: 0;
}

.submitQuestionButton {
  width: 200px;
  padding: 14px;
}

.transcript-answer-status {
  color: darkorange;
  display:inline-block;
}
