body {
  /* margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  height: 100%;
}

/* button {
  font-size: inherit;
  font-family: inherit;
  border: 0;
  padding: 0;
  cursor: pointer;
}

.no-focus-outline a:focus,
.no-focus-outline button:focus {
  outline: none;
} */
